import React, { createContext, useContext, useState } from 'react';
import { INavigation } from 'modules/types';

export interface StateContextType {
    routes: INavigation[];
    setRoutes: (routes: INavigation[]) => void;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const AppContext = createContext<StateContextType>(null!);

function AppProvider({ children }: React.PropsWithChildren<{}>) {
    const [routes, setRoutes] = useState<INavigation[]>([]);

    return (
        <AppContext.Provider
            value={{
                routes,
                setRoutes,
            }}
        >
            {children}
        </AppContext.Provider>
    );
}

function useAppContext() {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useAppState must be used within the AppStateProvider');
    }
    return context;
}

export { AppProvider, useAppContext };
